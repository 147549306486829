.jodit-workplace img {
  display: revert;
  vertical-align: revert;
}

.jodit-add-new-line,
.jodit-status-bar,
.jodit-toolbar-button_96,
.jodit-toolbar-button_ul .jodit-toolbar-button__trigger,
.jodit-toolbar-button_ol .jodit-toolbar-button__trigger,
.jodit-dialog .jodit-dialog__resizer {
  display: none !important;
}

.jodit-container .jodit-toolbar__box {
  border-top-right-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
  background-color: #f8f9fc !important;
  border-color: #dae3f2 !important;
  width: auto !important;
}

.jodit-container.jodit-jodit_fullsize_true .jodit-toolbar__box {
  position: static;
  width: 100% !important;
}

.jodit-ui-separator {
  border-color: #dae3f2 !important;
}

.jodit-toolbar__box .jodit-toolbar-editor-collection {
  background-image: repeating-linear-gradient(
    transparent 0,
    transparent 37px,
    #dae3f2 38px
  ) !important;
}

.jodit-wysiwyg ul {
  list-style: disc;
  padding: unset;
  padding-inline-start: 2.5rem;
  margin-bottom: 0.625rem;
}

.jodit-wysiwyg ol {
  list-style: auto;
  padding: unset;
  padding-inline-start: 2.5rem;
  margin-bottom: 0.625rem;
}

.jodit-wysiwyg pre {
  border: 1px solid;
  border-color: #dae3f2;
  padding: 0.625rem;
  border-radius: 0.25rem;
  background-color: #f8f9fc;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}

.jodit-wysiwyg hr {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

.jodit-wrapper > div {
  @apply !rounded-lg;
  border: 1px solid #dae3f2 !important;
  background-color: transparent;
}

.jodit-wrapper.error > div {
  @apply !border-red;
}

.jodit-workplace {
  @apply rounded-lg overflow-hidden;
  border: none !important;
}

.jodit-wrapper.disabled .jodit-workplace {
  @apply !bg-gray;
}

.jodit-dialog__overlay {
  background-color: #3b3953;
  opacity: 50%;
}

.jodit-dialog .jodit-dialog__header {
  border-bottom: 0;
  cursor: default;
}

.jodit-dialog .jodit-dialog__header .jodit-dialog__header-title {
  font-size: 24px;
  font-weight: 700;
}

.jodit-dialog .jodit-dialog__header .jodit-dialog__header-toolbar {
  position: absolute;
  right: 0.625rem;
  top: 15px;
}

@media (max-width: 480px) {
  .jodit-dialog:not(.jodit-dialog_adaptive_false) .jodit-dialog__panel {
    top: 50% !important;
    left: 50% !important;
    height: auto !important;
    width: calc(100% - 1rem) !important;
    min-width: 0 !important;
    min-height: 0 !important;
    max-height: calc(100vh - 1rem);
    transform: translateX(-50%) translateY(-50%);
  }
}

.jodit-dialog .jodit-dialog__panel {
  top: 50% !important;
  left: 50% !important;
  width: calc(100% - 1rem) !important;
  max-width: max-content;
  max-height: calc(100vh - 1rem);
  height: auto !important;
  min-width: 0 !important;
  min-height: 0 !important;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 0.75rem;
  padding: 2.25rem;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.jodit-dialog .jodit-dialog__content {
  min-height: 0.625rem;
  width: fit-content;
  height: fit-content;
}

.jodit-dialog .jodit-dialog_prompt {
  max-width: 100%;
}

.jodit-ui-block .jodit-ui-button,
.jodit-dialog .jodit-dialog__footer .jodit-ui-button {
  border: 2px solid;
  border-color: #dae3f2;
  height: 2.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: white !important;
  color: #3b3953;
  border-radius: 9999px;
}

.jodit-ui-block .jodit-ui-button:hover,
.jodit-dialog .jodit-dialog__footer .jodit-ui-button:hover {
  border-color: #0083fc;
}

.jodit-ui-block .jodit-ui-button_variant_primary,
.jodit-dialog .jodit-dialog__footer .jodit-ui-button_variant_primary {
  border-color: #0083fc;
  background-color: #0083fc !important;
  color: white;
}

.jodit-ui-block .jodit-ui-button_variant_primary:hover,
.jodit-dialog .jodit-dialog__footer .jodit-ui-button_variant_primary:hover {
  border-color: #015bd7 !important;
  background-color: #015bd7 !important;
  color: white;
}

.jodit-ui-block .jodit-ui-button_variant_primary:focus:not([disabled]),
.jodit-dialog
  .jodit-dialog__footer
  .jodit-ui-button_variant_primary:focus:not([disabled]) {
  outline: none;
}

.jodit-dialog .jodit-dialog__footer {
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.25rem;
}

.jodit-custom-icon {
  fill: transparent !important;
}

.jodit-wysiwyg jodit-media {
  width: auto !important;
  height: auto !important;
  pointer-events: none;
}

.jodit-wysiwyg video {
  pointer-events: auto;
}

.jodit-wysiwyg blockquote {
  @apply border-gray-500 border-l-4;
  font-style: italic;
  padding: 4px;
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.jodit-wysiwyg [data-relation-object-id] {
  @apply bg-gray;
  @apply inline-block;
  @apply px-2.5;
  @apply py-1;
  @apply mx-1;
  @apply mt-1;
  @apply rounded;
}

.jodit-wysiwyg [data-relation-object-type]::before {
  @apply text-gray-500;
  @apply inline-block;
  content: attr(data-relation-object-type) ': ';
}

.jodit-wysiwyg [data-relation-object-type][data-relation-type-label]::before {
  content: attr(data-relation-type-label) ': ';
}

.jodit-wysiwyg [data-relation-object-title]::after {
  @apply inline-block;
  content: attr(data-relation-object-title);
}

.jodit-resizer {
  container-type: inline-size;
  container-name: resizer;
}

.jodit-resizer > span {
  max-width: 100%;
  max-height: 100%;
}

.jodit-jodit_fullsize_true,
.jodit_fullsize-box_true {
  z-index: 31 !important;
  height: 100% !important;
  position: fixed;
  inset: 0;
  width: 100% !important;
}

@container resizer (max-width: 70px) {
  .jodit-resizer > span {
    @apply absolute top-0 left-full mt-2 ml-4 text-left;
    background: none;
    text-wrap: nowrap;
  }

  .jodit-resizer > span::before {
    @apply absolute top-0 -left-2 bg-zinc-600;
    content: '';
    width: 65px;
    height: 25px;
    z-index: -1;
  }
}
